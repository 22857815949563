import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Tasks_AssignTask, Tasks_DeleteTask, Tasks_GetTaskTypes, Tasks_GetGetCustomTaskDetails, Tasks_GetTasks, Tasks_InsertEditTask } from '../../../../Services/Actions';
import Select from 'react-select';
import Popover from 'react-popover';

import * as styles from './styles';

import AssignClients from '../../../../Components/AssignClients';
import ButtonGroup from '../../../../Components/Buttons/ButtonGroup';
import { Icon as IconComponent, IconsListByName } from '../../../../Components/IconLibrary';
import * as IconsList from '../../../../Components/IconLibrary';
import Header from '../../Header';
import Loading from '../../../../Components/Loading';
import MultiText from '../../../../Components/Text/MultiText';
import PageText from '../../../../Components/Text/PageText';
import PaginationContainer from '../../../../Components/PaginationCounter';
import Spacer from '../../../../Components/Spacer';
import Toggle from '../../../../Components/Form/Toggle';
import TranslationsComponent from '../../../../Components/Translations';

import Info from '../../../../Modals/Info';

import { ReactComponent as AssignToSVG } from '../../../../Assets/SVG/AssignTo.svg';
import InfoIcon from '../../../../Assets/Icons/Info.png';
import Plus from '../../../../Assets/Icons/PlusSign_White.png';

const customStyles = {
    valueContainer: (provided, state) => ({
        ...provided,
        textOverflow: "ellipsis",
        maxWidth: "90%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "initial"
    }),
    input: (provided, state) => ({
        ...provided,
        minWidth: '20%'
    })
}

const defaultAssignTaskInfo = {
    ByDay: [],
    EndDate: '',
    Frequency: null,
    Interval: 0,
    SelectedUsers: [],
    SelectedUserIds: [],
    StartDate: '',
    Task: {}
}

const defaultTaskInfo = {
    Description: '',
    GoalAmount: '',
    Icon: '',
    ResponseHidden: 0,
    TaskId: 0,
    TaskName: '',
    TaskType: null,
    Translations: { DefaultLanguageId: null, Languages: [], Translations: {} }
}

const WeeklyFrequencyDays = [
    { DayId: 'MO', DayName: 'monday_abbr' },
    { DayId: 'TU', DayName: 'tuesday_abbr' },
    { DayId: 'WE', DayName: 'wednesday_abbr' },
    { DayId: 'TH', DayName: 'thursday_abbr' },
    { DayId: 'FR', DayName: 'friday_abbr' },
    { DayId: 'SA', DayName: 'saturday_abbr' },
    { DayId: 'SU', DayName: 'sunday_abbr' }
]

const MAX_DESCRIPTION_LENGTH = 200;
const MAX_NAME_LENGTH = 30;

class TasksComponent extends React.Component {
    TimeoutId = null;

    state = {
        TaskNameSearch_Previous: '',
        TaskNameSearch: '',
        Tasks: [],
        IsMoreResults: null,
        PageNo: 1,
        PageSize: 25,
        TotalPages: null,
        TotalRecords: null,

        Filters: {
            TaskTypes: {
                TaskTypes: [],
                IsLoading: false,
                Loaded: false,
                Selected: []
            }
        },

        PopoverIsOpen_Tasks: false,
        PopoverIsOpen_TaskTypes: false,

        ShowAddTaskModal: false,
        ShowAddTaskModal_Info: { ...defaultTaskInfo },

        ShowAddTaskIconsModal: false,

        ShowAssignTask: false,
        ShowAssignTask_Data: { ...defaultAssignTaskInfo },

        ShowDeleteTaskModal: false
    }

    componentDidMount() {
        this.onLoadTasks();
    }

    onAssignTask = () => {
        var { ByDay, EndDate, Frequency, Interval, SelectedUserIds, StartDate, Task: { TaskId } } = this.state.ShowAssignTask_Data;

        var ClientIds = SelectedUserIds.join(',');

        var RecurrencePattern = '';
        if (Frequency === 'Daily' || Frequency === 'Weekly') {
            RecurrencePattern += `FREQ=${Frequency.toUpperCase()};`;
            if (Frequency === 'Weekly') RecurrencePattern += `BYDAY=${ByDay.join(',')};`;
            RecurrencePattern += `INTERVAL=${Interval};`;
            RecurrencePattern += `UNTIL=${EndDate}`;
        }

        this.props.Tasks_AssignTask({ ClientIds, EndDate, RecurrencePattern, StartDate, TaskIds: `${TaskId}` }).then(() => {
            this.onToggleShowAssignTask({ ShowAssignTask: false });
        })
    }

    onBlurFilter_TaskTypes = () => {
        this.setState({ PageNo: 1 }, () => this.onLoadTasks());
    }

    onBlurSearchTasks = () => {
        var { TaskNameSearch, TaskNameSearch_Previous } = this.state;

        if (TaskNameSearch !== TaskNameSearch_Previous) {
            this.setState({ TaskNameSearch_Previous: TaskNameSearch, PageNo: 1 }, () => this.onLoadTasks());
        }
    }

    onChangeAssignTask_ByDay = ({ DayId, SelectedIndex }) => {
        var ByDay = [ ...this.state.ShowAssignTask_Data.ByDay ];

        if (SelectedIndex === -1) ByDay = [ ...ByDay, DayId ];
        else ByDay = [ ...ByDay.slice(0, SelectedIndex), ...ByDay.slice(SelectedIndex + 1) ];

        this.setState({ ShowAssignTask_Data: { ...this.state.ShowAssignTask_Data, ByDay }});
    }

    onChangeAssignTask_Clients = ({ SelectedClients: SelectedUsers }) => {
        var SelectedUserIds_New = SelectedUsers.map(User => User.UserId);
        SelectedUsers = SelectedUsers.map(User => ({ ...User, Sex: User.Gender }));

        this.setState({ ShowAssignTask_Data: { ...this.state.ShowAssignTask_Data, SelectedUsers, SelectedUserIds: SelectedUserIds_New }});
    }

    onChangeAssignTask_Frequency = Frequency => {
        this.setState({ ShowAssignTask_Data: { ...this.state.ShowAssignTask_Data, Frequency }});
    }

    onChangeAssignTask_EndDate = event => {
        this.setState({ ShowAssignTask_Data: { ...this.state.ShowAssignTask_Data, EndDate: event.target.value }});
    }

    onChangeAssignTask_StartDate = event => {
        this.setState({ ShowAssignTask_Data: { ...this.state.ShowAssignTask_Data, StartDate: event.target.value }});
    }

    onChangeTaskGoalAmount = event => {
        this.setState({ ShowAddTaskModal_Info: { ...this.state.ShowAddTaskModal_Info, GoalAmount: +event.target.value }});
    }

    onChangeTaskIcon = Icon => {
        this.setState({ ShowAddTaskModal_Info: { ...this.state.ShowAddTaskModal_Info, Icon }}, () => {
            this.onToggleShowAddTaskIconsModal({ ShowAddTaskIconsModal: false });
        });
    }

    onChangeTaskType = TaskType => {
        this.setState({ ShowAddTaskModal_Info: { ...this.state.ShowAddTaskModal_Info, TaskType }});
    }

    onChangeTasksSearch = event => {
        this.setState({ TaskNameSearch: event.target.value });
    }

    onChangeFilter_TaskTypes = data => {
        if (!data) data = [];
        
        var Filters = { ...this.state.Filters };
        Filters.TaskTypes.Selected = data;

        this.setState({ Filters });
    }

    onDeleteTask = () => {
        var { TaskId } = this.state.ShowAddTaskModal_Info;

        this.onToggleShowDeleteTaskModal({ ShowDeleteTaskModal: false });
        this.onToggleShowAddTaskModal({ ShowAddTaskModal: false });

        this.props.Tasks_DeleteTask({ TaskId }).then(() => {
            this.setState({ PageNo: 1, TaskNameSearch_Previous: '', TaskNameSearch: '' }, () => {
                this.onLoadTasks();
            });
        })
    }

    onFocusFilter_TaskTypes = () => {
        if (!this.state.Filters.TaskTypes.Loaded) {
            var Filters = { ...this.state.Filters };
            Filters.TaskTypes = { ...Filters.TaskTypes };
            Filters.TaskTypes.IsLoading = true;

            this.setState({ Filters }, () => {
                this.props.Tasks_GetTaskTypes().then(({ TaskTypes }) => {
                    Filters.TaskTypes.IsLoading = false;
                    Filters.TaskTypes.TaskTypes = TaskTypes;
                    Filters.TaskTypes.Loaded = true;
                    this.setState({ Filters });
                });
            });
        }
    }

    onInsertEditTask = () => {
        var { GoalAmount: Amount, Icon, ResponseHidden, TaskId, TaskType, Translations } = this.state.ShowAddTaskModal_Info;

        var NewTranslations = { DefaultLanguageId: Translations.DefaultLanguageId, Translations: [] };

        NewTranslations.Translations = Object.keys(Translations.Translations).map(key => {
            var { Description, TaskName } = Translations.Translations[key];

            return { LanguageId: key, TaskName: TaskName.substring(0, MAX_NAME_LENGTH), Description: Description.substring(0, MAX_DESCRIPTION_LENGTH) };
        });

        this.props.Tasks_InsertEditTask({ Amount: +Amount, Icon, ResponseHidden, TaskId, TaskType, Translations: NewTranslations }).then(() => {
            this.onToggleShowAddTaskModal({ ShowAddTaskModal: false });
            this.setState({ PageNo: 1, TaskNameSearch_Previous: '', TaskNameSearch: '' }, () => {
                this.onLoadTasks();
            });
        })
    }

    onLoadTasks = Increment => {
        var { TaskNameSearch: TaskName, Filters: { TaskTypes }, PageNo, PageSize  } = this.state;

        var TaskTypeIds = TaskTypes.Selected.length > 0 ? TaskTypes.Selected.map(({ Id }) => Id).join(',') : null;

        if (Increment) PageNo++;

        this.props.Tasks_GetTasks({ PageNo, PageSize, TaskName, TaskTypes: TaskTypeIds }).then(({ Tasks, IsMoreResults, TotalRecords }) => {
            var TotalPages = Math.floor(TotalRecords / PageSize) + 1;
            this.setState({ Tasks, IsMoreResults, TotalPages, TotalRecords });
        });
    }

    onPaginate = ({ NewPageNo }) => {
        if (this.statePageNo !== NewPageNo) this.setState({ PageNo: NewPageNo }, () => this.onLoadTasks());
    }

    onTogglePopover = PopoverType => {
        this.setState({ [PopoverType]: !this.state[PopoverType] }, () => {
            if (!this.state[PopoverType] && !!this.TimeoutId) clearTimeout(this.TimeoutId);
        });
    }

    onToggleResponseHidden = event => {
        this.setState({ ShowAddTaskModal_Info: { ...this.state.ShowAddTaskModal_Info, ResponseHidden: event.target.checked }});
    }

    onToggleShowAddTaskModal = ({ ShowAddTaskModal, Task = {} }) => {
        if (!ShowAddTaskModal) this.setState({ ShowAddTaskModal, ShowAddTaskModal_Info: { ...defaultTaskInfo } });
        else {
            var LanguageId = this.props.UserDetails.LanguageId;
            var { TaskId } = Task;
            if (Task) var GoalAmount = +Task.Amount;

            this.props.Tasks_GetGetCustomTaskDetails({ LanguageId, TaskId }).then(({ Task, Languages, Translations }) => {
                this.setState({ ShowAddTaskModal, ShowAddTaskModal_Info: { ...defaultTaskInfo, ...Task, ...GoalAmount && { GoalAmount }, Translations: { ...Translations, Languages } } })
            })
        }
    }

    onToggleShowAddTaskIconsModal = ({ ShowAddTaskIconsModal, Task }) => {
        this.setState({ ShowAddTaskIconsModal });
    }

    onToggleShowAssignTask = ({ event, ShowAssignTask, Task = {} }) => {
        if (event) event.stopPropagation();

        if (!ShowAssignTask) this.setState({ ShowAssignTask, ShowAssignTask_Data: { ...defaultAssignTaskInfo } });
        else this.setState({ ShowAssignTask, ShowAssignTask_Data: { ...defaultAssignTaskInfo, Task } })
    }

    onToggleShowDeleteTaskModal = ({ ShowDeleteTaskModal }) => {
        this.setState({ ShowDeleteTaskModal });
    }

    onTranslations_AddLanguage = () => {
        var NewShowAddTaskModal_Info = { ...this.state.ShowAddTaskModal_Info };
        var NewTranslations = { ...NewShowAddTaskModal_Info.Translations };

        var FirstNonUsedLanguage = NewTranslations.Languages.filter(({ LanguageId }) => !NewTranslations.Translations.hasOwnProperty(`${LanguageId}`))[0].LanguageId;

        NewTranslations.Translations = { ...NewTranslations.Translations, [`${FirstNonUsedLanguage}`]: { Description: '', TaskName: '', LanguageId: +FirstNonUsedLanguage } };

        NewShowAddTaskModal_Info.Translations = NewTranslations;

        this.setState({ ShowAddTaskModal_Info: NewShowAddTaskModal_Info });
    }

    onTranslations_ChangeDefault = LanguageId => {
        var NewShowAddTaskModal_Info = { ...this.state.ShowAddTaskModal_Info };
        var NewTranslations = { ...NewShowAddTaskModal_Info.Translations, DefaultLanguageId: LanguageId };

        NewShowAddTaskModal_Info.Translations = NewTranslations;

        this.setState({ ShowAddTaskModal_Info: NewShowAddTaskModal_Info });
    }

    onTranslations_ChangeLanguage = ({ NewLanguage, TranslationKey }) => {
        if (+NewLanguage.LanguageId !== +TranslationKey) {
            var NewShowAddTaskModal_Info = { ...this.state.ShowAddTaskModal_Info };
            var NewTranslations = { ...NewShowAddTaskModal_Info.Translations };

            NewTranslations.Translations = { ...NewTranslations.Translations, [`${NewLanguage.LanguageId}`]: { ...NewTranslations.Translations[`${TranslationKey}`] } };
            delete NewTranslations.Translations[`${TranslationKey}`];

            // If DefaultLanguageId Is The Language That Changes, Then Set DefaultLanguageId to NewLanguage
                if (+NewTranslations.DefaultLanguageId === +TranslationKey) NewTranslations = { ...NewTranslations, DefaultLanguageId: NewLanguage.LanguageId };

            NewShowAddTaskModal_Info.Translations = NewTranslations;

            this.setState({ ShowAddTaskModal_Info: NewShowAddTaskModal_Info });
        }
    }

    onTranslations_ChangeTranslation = ({ event, InputName, LanguageId }) => {
        var NewShowAddTaskModal_Info = { ...this.state.ShowAddTaskModal_Info };
        var NewTranslations = { ...NewShowAddTaskModal_Info.Translations };
        NewTranslations.Translations = { ...NewTranslations.Translations, [`${LanguageId}`]: { ...NewTranslations.Translations[`${LanguageId}`], [InputName]: event.target.value } };

        NewShowAddTaskModal_Info.Translations = NewTranslations;

        this.setState({ ShowAddTaskModal_Info: NewShowAddTaskModal_Info });
    }

    onTranslations_DeleteLanguage = LanguageId => {
        var NewShowAddTaskModal_Info = { ...this.state.ShowAddTaskModal_Info };
        var NewTranslations = { ...NewShowAddTaskModal_Info.Translations };
        NewTranslations.Translations = { ...NewTranslations.Translations };
        delete NewTranslations.Translations[`${LanguageId}`];

        NewShowAddTaskModal_Info.Translations = NewTranslations;

        this.setState({ ShowAddTaskModal_Info: NewShowAddTaskModal_Info });
    }

    renderAddTaskIconsModal = () => {
        var { ShowAddTaskIconsModal } = this.state;

        if (ShowAddTaskIconsModal) {
            return (
                <Info
                    InformationRenderer={this.renderAddTaskIconsModal_Information}
                    OnHide={() => this.onToggleShowAddTaskIconsModal({ ShowAddTaskIconsModal: false })}
                    Show={ShowAddTaskIconsModal}
                />
            );
        }
    }

    renderAddTaskIconsModal_Information = () => {
        var { t } = this.props;
        var { Icon: SelectedIcon } = this.state.ShowAddTaskModal_Info;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('pick_icon')} TextAlign="center" />

                <Spacer Size="small" />

                <styles.IconsListContainer className="IconsListContainer">
                    {
                        IconsListByName.map(IconName => {
                            var Id = IconName;

                            return (
                                <styles.IconContainer className="IconContainer" key={Id} Selected={Id === SelectedIcon} onClick={() => this.onChangeTaskIcon(Id)}>
                                    <IconComponent src={IconsList[IconName]} />
                                </styles.IconContainer>
                            );
                        })
                    }
                </styles.IconsListContainer>
            </>
        );

    }

    renderAddTaskModal_Information = () => {
        var { t } = this.props;
        var UserDetails = this.props.UserDetails;
        var { GoalAmount, Icon, ResponseHidden, TaskId, TaskType, Translations } = this.state.ShowAddTaskModal_Info;

        var ReadOnly = false;
        if (+TaskId) ReadOnly = true;

        var Disabled = !Icon || !Translations.Translations[`${Translations.DefaultLanguageId}`].TaskName || !TaskType || ((TaskType === 'MinQuantity' || TaskType === 'MaxQuantity') && !GoalAmount);

        var Buttons = [];
        Buttons.push({ BackgroundColor: 'white-concrete-hover', BackgroundColorHover: 'gray-dark', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onToggleShowAddTaskModal({ ShowAddTaskModal: false }), Title: t('_cancel') });
        Buttons.push({ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', OnClick: () => this.onInsertEditTask(), Title: t('_save') });

        if (+TaskId) Buttons.push({ BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onToggleShowDeleteTaskModal({ ShowDeleteTaskModal: true }), Title: t('delete_with_first_char_uppercase') });

        return (
            <>
                <styles.BodyHeader className="BodyHeader">
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text={+TaskId ? t('edit_task') : t('create_task')} TextAlign="left" />
                </styles.BodyHeader>

                <Spacer Size="small" />

                <styles.InsertEditModalContainer className="InsertEditModalContainer">
                    {/* Translation Component */}
                        <TranslationsComponent
                            ContentType="Task"
                            DefaultLanguageId={Translations.DefaultLanguageId || UserDetails.LanguageId}
                            Languages={Translations.Languages}
                            OnAddLanguage={this.onTranslations_AddLanguage}
                            OnChangeDefault={this.onTranslations_ChangeDefault}
                            OnChangeLanguage={this.onTranslations_ChangeLanguage}
                            OnChangeTranslation={this.onTranslations_ChangeTranslation}
                            OnDeleteLanguage={this.onTranslations_DeleteLanguage}
                            Translations={Translations.Translations}
                        />

                    <Spacer Size="small" />

                    {/* Response Hidden */}
                        <MultiText
                            TextAlign="left"
                            Texts={[
                                { FontFamily: 'medium', FontSize: 'medium-2', Text: t('status_hide_response_title') },
                                { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                            ]}
                        />

                        <Spacer Size="small" />

                        <Toggle
                            Checked={!!ResponseHidden}
                            OffLabel={t('_no')}
                            OnChange={this.onToggleResponseHidden}
                            OnLabel={t('_yes')}
                        />

                    {/* Icon */}
                        <MultiText
                            TextAlign="center"
                            Texts={[
                                { FontFamily: 'medium', FontSize: 'medium-2', Text: t('task_icon') },
                                { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                            ]}
                        />

                        <Spacer Size="small" />

                        {
                            !!Icon &&
                            <styles.SelectedIconContainer className="SelectedIconContainer">
                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('WorkoutPlanSettings_selected')} TextAlign="left" />

                                <IconComponent src={IconsList[Icon]} />
                                
                                <Spacer Size="small" />
                            </styles.SelectedIconContainer>
                        }

                        <ButtonGroup
                            Buttons={[{ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onToggleShowAddTaskIconsModal({ ShowAddTaskIconsModal: true }), Title: t('view_icons_list') }]}
                            ButtonWidth="fit-content"
                            NotTouching
                            OwnRows={false}
                        />

                        <Spacer Size="small" />

                        {/* Task Type */}
                            <MultiText
                                TextAlign="center"
                                Texts={[
                                    { FontFamily: 'medium', FontSize: 'medium-2', Text: t('task_type_which_is_it') },
                                    { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                                ]}
                            />
        
                            <Spacer Size="small" />
        
                            <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                                <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={ReadOnly ? () => null : () => this.onChangeTaskType(1)} ReadOnly={ReadOnly} Selected={TaskType === 1} />

                                <styles.RadioButtonPageText ElementType="span" FontFamily={TaskType === 1 ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={ReadOnly ? () => null : () => this.onChangeTaskType(1)} ReadOnly={ReadOnly} Text={t('task_type_checkmark_description')} TextAlign="left" />
                            </styles.RadioButtonParentContainer>

                            <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                                <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={ReadOnly ? () => null : () => this.onChangeTaskType(2)} ReadOnly={ReadOnly} Selected={TaskType === 2} />
            
                                <styles.RadioButtonPageText ElementType="span" FontFamily={TaskType === 2 ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={ReadOnly ? () => null : () => this.onChangeTaskType(2)} ReadOnly={ReadOnly} Text={t('task_type_minquantity_description')} TextAlign="left" />
                            </styles.RadioButtonParentContainer>

                            <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                                <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={ReadOnly ? () => null : () => this.onChangeTaskType(3)} ReadOnly={ReadOnly} Selected={TaskType === 3} />

                                <styles.RadioButtonPageText ElementType="span" FontFamily={TaskType === 3 ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={ReadOnly ? () => null : () => this.onChangeTaskType(3)} ReadOnly={ReadOnly} Text={t('task_type_maxquantity_description')} TextAlign="left" />
                            </styles.RadioButtonParentContainer>

                            {
                                (TaskType === 2 || TaskType === 3) &&
                                <>
                                    <Spacer Size="small" />

                                    <MultiText
                                        TextAlign="center"
                                        Texts={[
                                            { FontFamily: 'medium', FontSize: 'medium-2', Text: t('task_goal_amount') },
                                            { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                                        ]}
                                    />

                                    <Spacer Size="small" />

                                    <styles.StyledTemplateName
                                        NoLabel
                                        NoMargin
                                        OnChange={this.onChangeTaskGoalAmount}
                                        Placeholder={t('task_goal_amount')}
                                        Size="medium"
                                        Type="number"
                                        Value={GoalAmount}
                                    />
                                </>
                            }

                    <Spacer Size="medium" />

                    <ButtonGroup
                        Buttons={Buttons}
                        ButtonWidth="fit-content"
                        NotTouching
                        OwnRows={false}
                    />
                </styles.InsertEditModalContainer>
            </>
        );
    }

    renderAssignTask = () => {
        var { t } = this.props;
        var { ByDay, EndDate, Frequency, SelectedUsers, SelectedUserIds, StartDate, Task } = this.state.ShowAssignTask_Data;

        var Today = new Date();

        var MinDate = Today.toJSON().slice(0,10);
        var MaxDate = new Date(Today.setFullYear(Today.getFullYear() + 1)).toJSON().slice(0,10);
        var MinDate_EndDate = StartDate;

        var Disabled = !StartDate || SelectedUserIds.length === 0 || !Frequency || ((Frequency === 'Daily' || Frequency === 'Weekly') && !EndDate) || (Frequency === 'Weekly' && ByDay.length === 0);

        var Buttons = [];
        Buttons.push({ BackgroundColor: 'white-concrete-hover', BackgroundColorHover: 'gray-dark', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onToggleShowAssignTask({ ShowAssignTask: false }), Title: t('_cancel') });
        Buttons.push({ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', OnClick: () => this.onAssignTask(), Title: t('_save') });

        return (
            <styles.AssignTaskContainer className="AssignTaskContainer">
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('assign_to_clients')} TextAlign="left" />

                <Spacer Size="small" />

                <styles.TasksContainer className="TasksContainer">
                    {this.renderTask({ ...Task, ReadOnly: true })}
                </styles.TasksContainer>

                <Spacer Size="small" />

                {/* Start Date */}
                    <MultiText
                        TextAlign="left"
                        Texts={[
                            { FontFamily: 'medium', FontSize: 'medium-2', Text: t('start_date') },
                            { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                        ]}
                    />

                    <styles.StyledAccountInput
                        FontSize="medium-1"
                        Min={MinDate}
                        OnChange={this.onChangeAssignTask_StartDate}
                        Size="large"
                        Type="date"
                        Value={StartDate}
                    />

                <Spacer Size="small" />

                {/* Repeating */}
                    {/* Frequency */}
                        <MultiText
                            TextAlign="left"
                            Texts={[
                                { FontFamily: 'medium', FontSize: 'medium-2', Text: t('tasks_want_to_repeat') },
                                { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                            ]}
                        />

                        <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                            <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeAssignTask_Frequency('Daily')} Selected={Frequency === 'Daily'} />

                            <styles.RadioButtonPageText ElementType="span" FontFamily={Frequency === 'Daily' ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeAssignTask_Frequency('Daily')} Text={t('daily')} TextAlign="left" />
                        </styles.RadioButtonParentContainer>

                        <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                            <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeAssignTask_Frequency('Weekly')} Selected={Frequency === 'Weekly'} />

                            <styles.RadioButtonPageText ElementType="span" FontFamily={Frequency === 'Weekly' ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeAssignTask_Frequency('Weekly')} Text={t('weekly')} TextAlign="left" />
                        </styles.RadioButtonParentContainer>

                        {
                            Frequency === 'Weekly' &&
                            <>
                                <Spacer Size="small" />

                                <styles.WeeklyFrequencyDaysContainer className="WeeklyFrequencyDaysContainer">
                                    {
                                        WeeklyFrequencyDays.map(({ DayId, DayName }) => {
                                            var SelectedIndex = ByDay.indexOf(DayId);
                                            var Selected = SelectedIndex !== -1;

                                            return (
                                                <styles.WeeklyFrequencyDayContainer className="WeeklyFrequencyDayContainer" key={DayId}>
                                                    <PageText FontColor={Selected ? 'blue-abel' : 'black'} FontFamily={Selected ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onChangeAssignTask_ByDay({ DayId, SelectedIndex })} Text={t(DayName)} TextAlign="left" />
                                                </styles.WeeklyFrequencyDayContainer>
                                            );
                                        })
                                    }
                                </styles.WeeklyFrequencyDaysContainer>
                                
                                <Spacer Size="small" />
                            </>
                        }

                        <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                            <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeAssignTask_Frequency('None')} Selected={Frequency === 'None'} />

                            <styles.RadioButtonPageText ElementType="span" FontFamily={Frequency === 'None' ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeAssignTask_Frequency('None')} Text={t('no_repeat')} TextAlign="left" />
                        </styles.RadioButtonParentContainer>

                    {/* End Date */}
                        {
                            (Frequency === 'Daily' || Frequency === 'Weekly') &&
                            <>
                                <Spacer Size="small" />

                                <MultiText
                                    TextAlign="left"
                                    Texts={[
                                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('end_date') },
                                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                                    ]}
                                />

                                <styles.StyledAccountInput
                                    Disabled={!StartDate}
                                    FontSize="medium-1"
                                    Max={MaxDate}
                                    Min={MinDate_EndDate}
                                    OnChange={this.onChangeAssignTask_EndDate}
                                    Size="large"
                                    Type="date"
                                    Value={EndDate}
                                />
                            </>
                        }

                    {/* Clients */}
                        <AssignClients
                            HideTitle
                            HorizontalScroll={false}
                            OnSelectClients={Users => this.onChangeAssignTask_Clients(Users)}
                            SelectedUserIds={SelectedUserIds}
                            SelectedUsers={SelectedUsers}
                        />

                {/* Save Button */}
                    <Spacer Size="small" />

                    <ButtonGroup
                        Buttons={Buttons}
                        ButtonWidth="fit-content"
                        NotTouching
                        OwnRows={false}
                    />
            </styles.AssignTaskContainer>
        );
    }

    renderBody = () => {
        var { ShowAddTaskModal, ShowAssignTask } = this.state;

        return (
            <styles.BodyContainer className="BodyContainer">
                {
                    ShowAddTaskModal ?
                    this.renderAddTaskModal_Information()
                :
                    ShowAssignTask ?
                    this.renderAssignTask()
                :
                    this.renderTasks()
                }
            </styles.BodyContainer>
        );
    }
    
    renderTasks = () => {
        var { t } = this.props;
        var { UserId } = this.props.UserDetails;
        var { Tasks, PageNo, PageSize, TotalPages, TotalRecords } = this.state;

        return (
            <>
                <styles.BodyHeader className="BodyHeader">
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text={t('tasks')} TextAlign="left" />

                    {
                        (+UserId === 16 || +UserId === 1) &&
                        <styles.BodyHeaderRight className="BodyHeaderRight">
                            <ButtonGroup
                                Buttons={[{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', Icon: Plus, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: () => this.onToggleShowAddTaskModal({ ShowAddTaskModal: true }), Title: t('new_task') }]}
                                ButtonWidth="fit-content"
                                ContainerWidth="fit-content"
                                JustifyContent="center"
                            />
                        </styles.BodyHeaderRight>
                    }
                </styles.BodyHeader>

                <Spacer Size="small" />

                {this.renderViewCount()}

                {
                    TotalRecords > 0 &&
                    <>
                        <Spacer Size="small" />

                        {
                            (PageNo !== 1 || PageSize < TotalRecords) &&
                            <>
                                <PaginationContainer OnPaginate={this.onPaginate} PageNo={PageNo} PageSize={PageSize} TotalPages={TotalPages} />
        
                                <Spacer Size="medium" />
                            </>
                        }

                        <styles.TasksContainer className="TasksContainer">
                            {Tasks.map(Task => this.renderTask(Task))}
                        </styles.TasksContainer>
                    </>
                }
            </>
        );
    }

    renderFilters = () => {
        var { t } = this.props;

        const popoverProps_Tasks = {
            isOpen: this.state.PopoverIsOpen_Tasks,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_Tasks'),
            body: this.renderPopoverContent('Tasks'),
            tipSize: 0.01
        }

        const popoverProps_TaskTypes = {
            isOpen: this.state.PopoverIsOpen_TaskTypes,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_TaskTypes'),
            body: this.renderPopoverContent('TaskTypes'),
            tipSize: 0.01
        }

        var { TaskNameSearch, Filters: { TaskTypes }} = this.state;

        return (
            <styles.FiltersContainer className="FiltersContainer">
                <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('MealPlan_Filters')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <styles.FiltersSubContainer className="FiltersSubContainer">
                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_search')} />

                            <Popover {...popoverProps_Tasks}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_Tasks')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_Tasks')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <styles.SearchUsersInput
                            FontSize="medium-1"
                            NoLabel
                            NoMargin
                            OnBlur={this.onBlurSearchTasks}
                            OnChange={this.onChangeTasksSearch}
                            Placeholder={t('_search')}
                            Size="medium"
                            Type="text"
                            Value={TaskNameSearch}
                        />
                    </styles.FilterContainer>

                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('task_types')} />

                            <Popover {...popoverProps_TaskTypes}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_TaskTypes')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_TaskTypes')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <Select
                            closeMenuOnSelect={false}
                            getOptionLabel={option => option.TaskType}
                            getOptionValue={option => option.Id}
                            isLoading={TaskTypes.IsLoading}
                            isMulti
                            // components={{ ValueContainer }}
                            hideSelectedOptions={true}
                            isSearchable
                            onBlur={this.onBlurFilter_TaskTypes}
                            onChange={data => this.onChangeFilter_TaskTypes(data)}
                            onFocus={this.onFocusFilter_TaskTypes}
                            options={TaskTypes.TaskTypes}
                            placeholder={t('filter_by_task_type')}
                            styles={customStyles}
                            menuPosition="fixed"
                        />
                    </styles.FilterContainer>
                </styles.FiltersSubContainer>
            </styles.FiltersContainer>
        );
    }

    renderPopoverContent = ContentType => {
        var { t } = this.props;

        return (
            <styles.PopoverContent className="PopoverContent">
                {ContentType === 'Tasks' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('tasks_search_by_name_description')} TextAlign="center" />}
                {ContentType === 'TaskTypes' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('tasks_search_by_tasktype_description')} TextAlign="center" />}
            </styles.PopoverContent>
        );
    }

    renderShowDeleteTaskModal = () => {
        var { t } = this.props;
        var { ShowDeleteTaskModal } = this.state;

        if (ShowDeleteTaskModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteTask}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    Information={t('tasks_delete_warning')}
                    OnHide={() => this.onToggleShowDeleteTaskModal({ ShowDeleteTaskModal: false })}
                    Show={ShowDeleteTaskModal}
                    Size="small"
                />
            );
        }
    }

    renderTask = Task => {
        var { UserId } = this.props.UserDetails;
        var { Icon, ReadOnly, TaskId, TaskName } = Task;
        var IsOwner = (+UserId === 16 || +UserId === 1);

        return (
            <styles.TaskContainer className="TaskContainer" key={TaskId} onClick={(ReadOnly || !IsOwner) ? () => null : () => this.onToggleShowAddTaskModal({ ShowAddTaskModal: true, Task })} ReadOnly={(ReadOnly || !IsOwner)}>
                <styles.TaskContainerLeft className="TaskContainerLeft">
                    <IconComponent src={IconsList[Icon]} />
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={TaskName} TextAlign="left" />
                </styles.TaskContainerLeft>

                {
                    !ReadOnly &&
                    <ButtonGroup
                        Buttons={[{ BackgroundColorHover: 'gray-dark', Border: 'none', Color: 'white', ColorHover: 'white', IconSVG: AssignToSVG, IconPosition: 'top', IconOnly: true, IconSize: { Height: '20px', Width: '20px' }, OnClick: event => this.onToggleShowAssignTask({ event, ShowAssignTask: true, Task }) }]}
                        ButtonWidth="fit-content"
                        ContainerWidth="fit-content"
                        JustifyContent="center"
                    />
                }
            </styles.TaskContainer>
        );
    }

    renderViewCount = () => {
        var { t } = this.props;

        var { PageNo, PageSize, TotalRecords } = this.state;

        if (!TotalRecords) TotalRecords = 0;

        return (
            <div className="ViewCount">
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('items_count_viewing')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${!TotalRecords ? 0 : ((PageNo - 1) * PageSize) + 1} - ${(PageNo * PageSize) < TotalRecords ? (PageNo * PageSize) : TotalRecords}`} />
                &nbsp;
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('of')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${TotalRecords} ${t('tasks')}`} />
            </div>
        )
    }

    render() {
        return (
            <>
                {this.props.TryingTasksAction && <Loading />}

                {<Header HideTrashLink LibraryType="Tasks" ShowTrash={false} />}

                {this.renderFilters()}
                {this.renderBody()}

                {this.renderAddTaskIconsModal()}
                {this.renderShowDeleteTaskModal()}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        UserDetails: state.Auth.UserDetails,

        TryingTasksAction: state.Tasks.TryingTasksAction,
        TryingTasksActionError: state.Tasks.TryingTasksActionError
    };
};

export default withTranslation()(connect(mapStateToProps, { Tasks_AssignTask, Tasks_DeleteTask, Tasks_GetTaskTypes, Tasks_GetGetCustomTaskDetails, Tasks_GetTasks, Tasks_InsertEditTask } )(TasksComponent));